import {useNavigate} from 'react-router-dom'

import {useDraggableNode} from '../../hooks'


import Next from '@mui/icons-material/SkipNext'
import Pause from '@mui/icons-material/Pause'
import Previous from '@mui/icons-material/SkipPrevious'
import CloseIcon from '@mui/icons-material/Close'
import PlayArrow from '@mui/icons-material/PlayArrow'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import {SiApplemusic} from 'react-icons/si'
// import {PiSpotifyLogoFill} from 'react-icons/pi'


import {Image} from '../generics'

import styles from '../../styles/media-player/mediaPlayer.module.scss'





const MediaPlayerCompact = props => {

  const navigate = useNavigate()


  const {
    image,
    isPlaying,
    // spotifyLink,
    appleMusicLink,
    handleClose,
    composerID,
    composerType,
    expandPlayer,
    playNextTrack,
    handlePlayClick,
    playPreviousTrack
  } = props



  useDraggableNode('audio-player')



  function navigateToComposersPage(e) {
    e.stopPropagation()

    if (['event', 'performance'].includes(composerType.toLowerCase())) {
      navigate(`/performances/${composerID}`)
    } else {
      navigate(`/${composerType.toLowerCase()}s/${composerID}`)
    }
  }



  return (
    <div id='audio-player' className={styles.compactRoot}>
      <div className={styles.imageContainer}>
        <Image
          src={image} 
          alt='track' 
          id='audio-player-image'
          onClick={navigateToComposersPage}
          className={styles.image}
        />

        <div onClick={navigateToComposersPage} className={styles.hole} />

        {Boolean(appleMusicLink) &&
          <a href={appleMusicLink} target='_blank'>
            <SiApplemusic className={styles.musicServiceIcon} /> 
          </a>
        }
      </div>




      <div className={styles.controlsContainer}>
        <IconButton onClick={playPreviousTrack} size='large'>
          <Previous color='primary' />
        </IconButton>


        <IconButton
          size='large'
          onClick={handlePlayClick}
          className={styles.playButton}
        >
          {isPlaying ? <Pause /> : <PlayArrow />}
        </IconButton>


        <IconButton onClick={playNextTrack} size='large'>
          <Next color='primary' />
        </IconButton>
      </div>



      <div className={styles.buttonsContainer}>
        <CloseIcon 
          onClick={handleClose}
          className={styles.closeIcon} 
        />
    

        <ExpandMoreIcon
          onClick={expandPlayer}
          className={styles.expandIcon} 
        />
      </div>
    </div>
  )
}



export default MediaPlayerCompact





