import {useRef, useEffect, useState} from 'react'

import {useNavigate} from 'react-router-dom'

import {useDraggableNode} from '../../hooks'


import Next from '@mui/icons-material/SkipNext'
import Pause from '@mui/icons-material/Pause'
import Skeleton from '@mui/material/Skeleton';
import Previous from '@mui/icons-material/SkipPrevious'
import PlayArrow from '@mui/icons-material/PlayArrow'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import {SiApplemusic} from 'react-icons/si'
// import {PiSpotifyLogoFill} from 'react-icons/pi'


import TrackSlider from './TrackSlider'
import VolumeControl from './VolumeControl'

import {Image, FavoriteIconButton} from '../generics'

import styles from '../../styles/media-player/mediaPlayer.module.scss'





const MediaPlayerExpanded = props => {

  const navigate = useNavigate()

  const videoRef = useRef()
  const closeIconRef = useRef()
  const minimizeIconRef = useRef()


  const [canPlay, setCanPlay] = useState(false)


  const {
    track,
    imageRef,
    audioRef,
    isMuted,
    volume,
    setVolume,
    setIsMuted,
    isPlaying,
    handleEnd,
    handleClose,
    playNextTrack,
    minimizePlayer,
    isMobileDevice,
    handlePlayClick,
    playPreviousTrack
  } = props



  const {
    name, 
    image,
    video,
    composer,
    composerId, 
    composerType,
    // spotifyLink,
    appleMusicLink,
    imageDerivatives
  } = track.attributes || {}


  const isAppleTrack = Boolean(appleMusicLink)



  useDraggableNode('audio-player')





  useEffect(() => {
    if (imageRef.current) {
      const animationState = isPlaying ? 'running' : 'paused'
      imageRef.current.style.animationPlayState = animationState
    }
  }, [isPlaying, imageRef])




  useEffect(() => {
    setCanPlay(false)


    function handleCanPlay() {
      setCanPlay(true)
    }

    const videoElement = videoRef.current

    videoElement?.load()
    videoElement?.addEventListener('canplay', handleCanPlay)


    return () => {
      videoElement?.removeEventListener('canplay', handleCanPlay)
    }
  }, [video])




  useEffect(() => {
    if (!canPlay) return


    async function togglePlay() {
      if (isPlaying) {
        videoRef.current.play()
      } else {
        await videoRef.current.pause()
      }
    }

    togglePlay()
  }, [isPlaying, canPlay])




  useEffect(() => {
    videoRef.current.muted = isMuted
  }, [isMuted])




  useEffect(() => {
    videoRef.current.volume = volume / 100
  }, [volume])



  // Change the color of the close icon depending on if a video 
  // or track is playing
  useEffect(() => {
    const closeIcon = closeIconRef.current
    const minimizeIcon = minimizeIconRef.current

    if (video) {
      closeIcon.style.color = '#fff'
      minimizeIcon.style.color = '#fff'
    } else {
      closeIcon.style.color = 'grey'
      minimizeIcon.style.color = 'black'
    }
  }, [video])




  function navigateToComposersPage() {
    if (['event', 'performance'].includes(composerType.toLowerCase())) {
      navigate(`/performances/${composerId}`)
    } else {
      navigate(`/${composerType.toLowerCase()}s/${composerId}`)
    }
    

    // For small devices, minmize the player to show the navigation
    if (isMobileDevice) {
      minimizePlayer()
    }

    return
  }




  function toggleMute() {
    setIsMuted(!isMuted)
  }




  return (
    <div id='audio-player' className={styles.root}>
      <CloseIcon
        ref={closeIconRef}
        className={styles.closeIcon} 
        onClick={handleClose} 
      />



      <div 
        className={styles.videoContainer}
        style={{
          display: video ? 'block' : 'none',
          borderRadius: isMobileDevice ? '0' : '8px'
        }}
      >
        <video 
          id='video-player'
          ref={videoRef}
          width='100%' 
          muted 
          autoPlay
          playsInline
          onEnded={handleEnd}
        >
          <source src={video} type='video/mp4' />
        </video>
      </div>

    

      {!video &&
        <>
          <div className={styles.imageContainer}>
            <Image
              id='audio-player-image'
              alt='track'
              src={imageDerivatives?.small || image}
              ref={imageRef}
              loading='eager'
              style={{overflow: 'hidden'}}
              className={styles.image} 
            /> 
            
            <div className={styles.hole} />


            {isAppleTrack &&
              <SiApplemusic className={styles.musicServiceIcon} /> 
            }
          </div>



          <div className={styles.infoContainer}>
            <h3>
              {Boolean(name) ? name : 
                <Skeleton 
                  sx={{fontSize: '1.1rem'}} 
                  width={150} 
                />
              }
            </h3>

            <p>
              {(Boolean(name) || Boolean(composer)) ? composer : 
                <Skeleton
                  sx={{fontSize: '1rem'}}
                  width={100}
                />
              }
            </p>
          </div>



          <div className={styles.buttonsContainer}>
            {!isAppleTrack &&         
              <FavoriteIconButton
                id={track.id}
                type='track'
                color='rgba(0, 0, 0, 0.54)'
              />
            }


            {isAppleTrack &&
              <IconButton size='small'>
                <a href={appleMusicLink} target='_blank'>
                  <SiApplemusic size='27px' /> 
                </a>
              </IconButton>
            }

            <IconButton onClick={navigateToComposersPage} size='small'>
              <OpenInNewIcon />
            </IconButton>
          </div>



          <div className={styles.trackSlider}>
            <TrackSlider audioRef={audioRef} trackId={track.id} />
          </div>
        </>
      }



      <div className={styles.controlsContainer}>
        <div className={styles.largeControls}>
          <IconButton className={styles.button} onClick={playPreviousTrack}>
            <Previous color='secondary' />
          </IconButton>


          <IconButton className={styles.playButton} onClick={handlePlayClick}>
            {isPlaying 
              ? <Pause fontSize='large' /> 
              : <PlayArrow fontSize='large' />
            }
          </IconButton>


          <IconButton className={styles.button} onClick={playNextTrack}>
            <Next color='secondary' />
          </IconButton>
        </div>




        <div 
          style={{
            gap: video ? '5px' : '0px',
            justifyContent: video ? 'center' : 'space-between'
          }} 
          className={styles.smallControls}
        >
          {!video &&
            <div className={styles.volumeContainer}>
              <VolumeControl 
                isMuted={isMuted}
                setIsMuted={setIsMuted}
                volume={volume}
                callback={setVolume} 
              />
            </div>
          }


          {video &&
            <IconButton className={styles.button} onClick={toggleMute}>
              {isMuted 
                ? <VolumeOffIcon color='secondary' /> 
                : <VolumeUpIcon  color='secondary' />
              }
            </IconButton>
          }


          <IconButton 
            ref={minimizeIconRef} 
            style={{
              transform: isMobileDevice ? 'rotate(180deg)' : 'rotate(0)',
              marginBottom: video ? '0px' : '5px'
            }} 
            className={styles.button}
            onClick={minimizePlayer}
          >
            <ExpandLessIcon fontSize='large' />
          </IconButton>


          {video &&
            <IconButton 
              className={styles.button} 
              onClick={navigateToComposersPage}
            >
              <OpenInNewIcon color='secondary' />
            </IconButton>
          }
        </div>
      </div>
    </div>
  )
}




export default MediaPlayerExpanded


