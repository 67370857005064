import {isEmpty} from 'lodash'


const INITIAL_STATE = {
  map: null,
  center: {
    lat: null, 
    lng: null
  },
  markers: {
    user: null,
    queue: {
      venues: [],
      performances: []
    },
    venues: {},
    performances: {},
    clickedMarkerData: {}
  }
}



const mapReducer = (state = INITIAL_STATE, action) => {
  let queue = []


  switch (action.type) {
    case 'SET_MAP':
      return {
        ...state, 
        map: action.payload
      }
    case 'PAN_TO':
      return {
        ...state, 
        center: action.payload
      }
    case 'SET_USER_MARKER':
      return {
        ...state,
        markers: {
          ...state.markers,
          user: action.payload
        }
      }
    case 'SET_CLICKED_MARKER_DATA':
      return { 
        ...state,
        markers: {
          ...state.markers,
          clickedMarkerData: action.payload
        }
      }
    case 'RESET_CLICKED_MARKER_DATA':
      return { 
        ...state,
        markers: {
          ...state.markers,
          clickedMarkerData: {}
        }
      }
    // ------------------------------------------------------------
    // The below deals with the marker queue, which represents
    // the markers that need to be added to the map
    // ------------------------------------------------------------
    case 'ADD_TO_PERFORMANCE_MAP_MARKER_QUEUE':
      queue = []


      // Only add to the queue if the marker for the event doesn't exist
      for (const event of action.payload.data) {
        if (!(event.id in state.markers.performances)) {
          queue.push(event)
        }
      }


      return {
        ...state,
        markers: {
          ...state.markers,
          queue: {
            ...state.markers.queue,
            performances: queue
          }
        }
      }
    case 'ADD_TO_VENUE_MAP_MARKER_QUEUE':
      queue = []


      // Only add to the queue if the marker for the event doesn't exist
      for (const venue of action.payload.data) {
        if (!(venue.id in state.markers.venues)) {
          queue.push(venue)
        }
      }


      return {
        ...state,
        markers: {
          ...state.markers,
          queue: {
            ...state.markers.queue,
            venues: queue
          }
        }
      }
    case 'CLEAR_PERFORMANCE_MAP_MARKER_QUEUE':
      return {
        ...state,
        markers: {
          ...state.markers,
          queue: {
            ...state.markers.queue,
            performances: []
          }
        }
      }
    case 'CLEAR_VENUE_MAP_MARKER_QUEUE':
      return {
        ...state,
        markers: {
          ...state.markers,
          queue: {
            ...state.markers.queue,
            venues: []
          }
        }
      }
    // ------------------------------------------------------------
    // The below deals with the actual markers saved to state
    // after they have been added to the map
    // ------------------------------------------------------------
    case 'SET_VENUE_MARKERS':
      // Set markers should only be called after all markers are cleard
      // this is a check to ensure this is the case
      if (!isEmpty(state.markers.performances)) {
        return state
      }

      return {
        ...state,
        markers: {
          ...state.markers,
          venues: action.payload,
        }
      }
    case 'ADD_NEW_VENUE_MARKERS':
      return {
        ...state,
        markers: {
          ...state.markers,
          venues: {
            ...action.payload,
            ...state.markers.venues
          }
        }
      }
    case 'SET_PERFORMANCE_MARKERS':
      // Set markers should only be called after all markers are cleard
      // this is a check to ensure this is the case
      if (!isEmpty(state.markers.performances)) {
        return state
      }

      return {
        ...state,
        markers: {
          ...state.markers,
          performances: action.payload
        }
      }
    case 'ADD_NEW_PERFORMANCE_MARKERS':
      return {
        ...state,
        markers: {
          ...state.markers,
          performances: {
            ...action.payload,
            ...state.markers.performances
          }
        }
      }
    case 'CLEAR_PERFORMANCE_MARKERS':
      Object.values(state.markers.performances).forEach(marker => {
        marker?.setMap(null)
      })

      return {
        ...state,
        markers: {
          ...state.markers,
          performances: {}
        }
      }
    case 'CLEAR_VENUE_MARKERS':
      Object.values(state.markers.venues).forEach(marker => {
        marker?.setMap(null)
      })

      return {
        ...state,
        markers: {
          ...state.markers,
          venues: {}
        }
      }
    default:
      return state
  }
}

export default mapReducer


