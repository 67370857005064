import {useEffect} from 'react'

import {startOfDay, addYears} from 'date-fns'

import {useSelector, useDispatch} from 'react-redux'

import {dateToUTC} from '../utils'

import {search} from '../actions'

import {selectFilters, selectSearchStatus} from '../selectors'




const date = new Date()


export function useSearchEventTracks() {

  const dispatch = useDispatch()
  
  const filters = useSelector(selectFilters)

  const status = useSelector(selectSearchStatus)


  useEffect(() => {
    if (status === 'requested') {
      const data = {} 


      // Add Location to search filters
      const {lat, lng, radius} = filters.location

      data.location = {lat, lng, radius: radius || 60}


      // Add time to search filters
      const start = filters.time.start || startOfDay(date)
      const end = filters.time.end || addYears(startOfDay(date), 1)
      const utcDate = {start: dateToUTC(start), end: dateToUTC(end)}

      data.time = utcDate

    
      // Add price to search filters if it exists 
      const min = filters.price.min
      const max = filters.price.max

      if (typeof min === 'number' && typeof max === 'number') {
        if (min === 0) {
          data.price = max
        } else {
          data.price_range = {min, max}
        }
      } else if (typeof max === 'number') {
        data.price = max
      }


      // Add tags if any are preent
      if (filters.tags) {
        data.tags = filters.tags
      }


      dispatch(search('search/performances/tracks', data, 'TRACK'))
    }
  }, [filters, status, dispatch])

}



export default useSearchEventTracks