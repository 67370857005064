import {createSelector} from 'reselect'



// Direct selectors for accessing state slices
export const selectFilters = state => state.search.filters
export const selectTags = state => state.search.filters.tags
export const selectTimeFilter = state => state.search.filters.time
export const selectPriceFilter = state => state.search.filters.price
export const selectLocationFilter = state => state.search.filters.location
export const selectSearchTypeFilter = state => state.search.filters.types

// Memoized selector to combine `tags` and `location` for venue filters
export const selectVenueFilters = createSelector(
  [selectTags, selectLocationFilter],
  (tags, location) => ({tags, location}) // Combines the tags array and location object
)