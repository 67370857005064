import React, {useState, useEffect} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import {format} from 'date-fns'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider} from '@mui/x-date-pickers'

import * as Yup from 'yup'
import {withFormik, Form, Field} from 'formik'

import {show} from '../../apis'

import {defaultAddress} from '../../helpers'


import {
  addHours,
  addYears,
  formatMoney,
  getTopAddress, 
  getBottomAddress,
  combineDateAndTime, 
  convertUTCDateToLocalDate,
} from '../../utils'


import { 
  create, 
  update, 
  showAlert, 
  addPerformanceToBand,
  addPerformanceToVenue,
  addPerformanceToArtist
} from '../../actions'


import {makeGetPerformanceById, makeGetVenueById} from '../../selectors'


import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Avatar from '@mui/material/Avatar'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import AvatarGroup from '@mui/material/AvatarGroup'
import InputAdornment from '@mui/material/InputAdornment'


import PerformanceFormPerformers from './PerformanceFormPerformers'


import {
  Errors,
  TagField,
  AspectBox,
  FormTitle,
  FormButton,
  ImageField,
  VideoField,
  MoneyField,
  CloseButton,
  FormikTextField,
  TimePickerField,
  DatePickerField,
  SlideTransition,
  ThreeDotProgress,
  GooglePlacesField,
  // SpotifyLinksField
  AppleMusicLinksField
} from '../generics'








const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;

    form {
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      flex-direction: row;
      padding-top: 20px;
      gap: 40px;
      padding-bottom: 50px;

      section {
        width: 100%;
        padding: 0px;
      }

      section:last-of-type {
        max-width: 520px;
      }
    }

    @media (max-width: 1200px) {
      form {
        flex-direction: column;
        max-width: 500px;
      }
    }

    @media (max-width: 900px) {
      padding: 40px;
    }
  `,
  autofillContainer: css`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 5px;
    font-size: 1rem;
    margin-bottom: 30px;

    p {
      font-size: 0.9rem;
      color: #7b7b7b;
      font-style: italic;
    }

    button {
      font-size: 12px;
      font-weight: 500;
      align-self: flex-start;
      min-height: 40px;
      background-color: black;
      color: #fff;
      transition: letter-spacing 0.3s ease;

      &:hover {
        background-color: black;
        color: #fff;
        letter-spacing: 0.05rem;
      }
    }
  `,
  imageContainer: css`
    width: 100%;
    max-width: 520px;
  `,
  fieldContainer: css`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    max-width: 520px;
  `,
  addressContainer: css`
    background-color: rgba(62, 166, 255, 0.1);
    padding: 15px;
    border-radius: 5px;
    border: 2px solid #dedede;
    margin-top: 10px;
  `,
  dateAndTimeContainer: css`
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;

    @media (max-width: 1000px) {
      flex-wrap: wrap;
    }
  `,
  date: css`
    flex: 1 1 0;

    @media (max-width: 900px) {
      flex: 1 0 100%;
    }
  `,
  time: css`
    flex: 1 1 0;

    @media (max-width: 900px) {
      flex: 1 0 calc(50% - 5px)
    }
  `,
  repeatContainer: css`
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;

    @media (max-width: 900px) {
      flex-wrap: wrap;
    }
  `,
  ticketContainer: css`
    width: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;

    & > div:first-of-type {
      width: 70%;
    }

    & > div:last-of-type {
      width: 30%;
    }
  `,
  tagAndPerformersContainer: css`
    margin-bottom: 40px;
  `,
  performersHeader: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 2px solid #E0E3E7;
    margin-bottom: 10px;
    margin-top: 25px;

    div {
      display: flex;
      justify-content: space-between;
    }

    h2 {
      font-weight: 500;
      letter-spacing: -0.1rem;
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 5px;
      color: #555555;
      font-size: 0.92rem;

      strong {
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    button {
      text-transform: uppercase;
      color: rgb(62, 166, 255);
      font-weight: 500;
      flex-shrink: 0;
    }
  `
}





const repeatOptions = {
  never: 'Doesn\'t Repeat',
  daily: 'Daily',
  weekly: 'Weekly',
  biweekly: 'Every Two Weeks',
  monthly: 'Monthly'
}




const PerformanceForm = props => {

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))


  const [open, setOpen] = useState(false)

  const [repeats, setRepeats] = useState('never')

  const [lastEvent, setLastEvent] = useState(false)

  const [isAddingPerformers, setIsAddingPerformers] = useState(false)


  const {
    close,
    values, 
    errors,
    touched,
    setValues,
    handleBlur,
    performance, 
    setFieldValue,
    setFieldError,
    isSubmitting, 
    creatorType,
    creatorId
  } = props
  

  const image = performance?.attributes.image || values.previous_performance_image

  const video = performance?.attributes.video || values.previous_performance_video


  const isAddressEmpty = Object.values(values.address).every(field => !Boolean(field))




  useEffect(() => {
    if (creatorId && creatorType && !lastEvent) {
      const url = '/' + creatorType + 's/' + creatorId + '/previous-performance'

      show(url).then(response => {
        if (response?.data?.data?.attributes) {

          const attributes = response.data.data.attributes

          setLastEvent({
            name: attributes.name,
            tickets: attributes.tickets,
            website: attributes.website,
            description: attributes.description,
            tags: attributes.tags,
            previous_performance_image: attributes.image
          })
        }
      })
    }
  }, [creatorId, creatorType, lastEvent])



  function prefill() {
    setValues({...values, ...lastEvent})
  }





  function handleStartTimeChange(newStartTime) {
    if (!touched.end_time) {
      if (newStartTime > values.end_time) {
        const newEndTime = addHours(newStartTime, 2)

        setFieldValue('end_time', newEndTime)
      }
    }
  }



  function handleRepeatChange(e) {
    const newValue = e.target.value

    setFieldValue('repeats', newValue)
    setRepeats(newValue)
  }



  function addPerformers() {
    setIsAddingPerformers(true)
  }



  function closePerformerForm() {
    setIsAddingPerformers(false)
  }




  const trimValue = (value, field) => e => {
    handleBlur(e)


    if (typeof value === 'string' || value instanceof String) {
      const trimmed = value.trim()

      if (trimmed !== value && values[field]) {
        setFieldValue(field, trimmed)
      }
    }
  }



  function handleTimeBlur(e) {
    handleBlur(e)
  }



  function openCalendar() {
    setOpen(true)
  }



  function closeCalendar() {
    setOpen(false)
  }




  return (
    <div css={styles.root}>
      <CloseButton onClick={close} />

      <Form autoComplete='off'>

      
        <section>
          <div css={styles.imageContainer}>
            <AspectBox aspect='2:3'>
              <ImageField 
                image={image} 
                fieldValue={values.image}
                setFieldValue={setFieldValue} 
                setFieldError={setFieldError}
              />
            </AspectBox>
          </div>

{/*          <div>
            <VideoField
              video={video}
              fieldValue={values.video}
              setFieldValue={setFieldValue} 
              setFieldError={setFieldError}
            />
          </div>*/}
        </section>


        <section>
          <FormTitle title={Boolean(performance) ? 'Edit Performance' : 'Create Performance'} />

          <Errors errors={errors?.unmatched} />


          {!Boolean(performance) && Boolean(lastEvent) &&
            <div css={styles.autofillContainer}>
              <Button variant='outlined' onClick={prefill}>
                PRE-FILL
              </Button>

              <p>Fill the form below using the last created event.</p>
            </div>
          }



          <div css={styles.fieldContainer}>

            <Field
              name='name'
              label='Name'
              variant='filled'
              fullWidth
              autoComplete='off'
              component={FormikTextField}
              onBlur={trimValue(values.name, 'name')}
            />


            <div css={styles.dateAndTimeContainer}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div css={styles.date}> 
                  <Field
                    open={open}
                    onOpen={openCalendar}
                    onClose={closeCalendar}
                    name='date'
                    label='Performance Date' 
                    fullWidth
                    inputProps={{onClick: openCalendar}}
                    component={DatePickerField}
                  />
                </div>


                <div css={styles.time}>
                  <Field
                    name='start_time'
                    label='Start Time'
                    fullWidth
                    variant='filled'
                    onBlur={handleTimeBlur}
                    onChange={handleStartTimeChange}
                    component={TimePickerField}
                  />
                </div>


                <div css={styles.time}>
                  <Field
                    name='end_time'
                    label='End Time'
                    fullWidth
                    variant='filled'
                    onBlur={handleTimeBlur}
                    component={TimePickerField}
                  />
                </div>
              </LocalizationProvider>
            </div>


            {!Boolean(performance) && 
              <div css={styles.repeatContainer}>
                <FormControl fullWidth> 
                  <InputLabel id='repeat-select-label' variant='filled'>
                    Repeats
                  </InputLabel>
                  
                  <Select
                    labelId='repeat-select-label'
                    id='repeat-select'
                    value={repeats}
                    label='Repeats'
                    variant='filled'
                    onChange={ handleRepeatChange }
                  >
                    {Object.keys(repeatOptions).map(key => (
                      <MenuItem key={key} value={key}>{repeatOptions[key]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Field
                  component={DatePickerField}
                  name='repeat_end'
                  label='Repeats Until'
                  minDate={values.date}
                  maxDate={addYears(new Date(), 1)}
                  disabled={ repeats === 'never' }
                />
              </div>
            }


            <div>
              <Field
                name='location'
                variant='filled'
                fullWidth
                placeholder='Address, venue, place...'
                component={GooglePlacesField}
              />


              <div css={styles.addressContainer}>
                {isAddressEmpty && 
                  `Once a location is selected from the dropdown above, how it will be
                   displayed on your band page will be shown here.`
                }

                {!isAddressEmpty &&
                  <>
                    <p><strong>{values.location}</strong></p>

                    <p>{getTopAddress(values.address)}</p>

                    <p>{getBottomAddress(values.address)}</p>
                  </>
                }
              </div>
            </div>


            <div css={styles.ticketContainer}>
              <Field
                variant='filled'
                name='tickets'
                label='Tickets Link'
                fullWidth
                component={FormikTextField}
                placeholder='www.tickets.com/ckord/tickets'
                onBlur={trimValue(values.tickets, 'tickets')}
              />


              <Field
                name='price'
                label='Price'
                fullWidth
                variant='filled'
                component={MoneyField}
                startAdornment={<InputAdornment position='start'>$</InputAdornment>}
              />
            </div>



            <Field
              name='website'
              label='Website'
              variant='filled'
              fullWidth
              component={FormikTextField}
              placeholder='Website URL'
              onBlur={trimValue(values.website, 'website')}
            />



            <Field
              variant='filled'
              component={FormikTextField}
              name='description'
              label='Details'
              fullWidth
              multiline={true}
              rows={12}
              onBlur={trimValue(values.description, 'description')}
              helperText={
                new Intl.NumberFormat().format(3500 - values.description.length).toString() + ' characters left'
              }
            />


            <div css={styles.tagAndPerformersContainer}>
              <Field
                name='tags'
                label='Tags'
                variant='filled'
                component={TagField}
                placeholder='Max of 5'
                fullWidth
              />


              {!Boolean(performance) &&
                <>
                  <div css={styles.performersHeader}>
                    <div>
                      <h2>Performers</h2>

                      <Button onClick={addPerformers} variant='text'>
                        Add / Invite
                      </Button>
                    </div>

                      <p>
                        <strong>Note 1:</strong> You can invite performers later.
                      </p>

                      <p>
                        <strong>Note 2:</strong> Performers will only show up under this 
                        performance once they accept an invite.
                      </p>
                  </div>


                  <AvatarGroup max={5} sx={{justifyContent: 'flex-end'}}>
                    {values.performers.map(performer => (
                      <Avatar 
                        key={performer.id} 
                        alt={performer.name} 
                        src={performer.image}
                        sx={{
                          width: isSmallScreen ? 40: 56, 
                          height: isSmallScreen ? 40: 56
                        }}
                      />
                    ))}
                  </AvatarGroup>
                </>
              }
            </div>



            <div>
              <Field 
                name='apple_music_links'
                label='Apple Music Links'
                variant='filled'
                component={AppleMusicLinksField}
                placeholder='Apple Music Artist URL'
                fullWidth
                helperText='Enter the Apple Music URLs of performers that will be performing at this event so users can listen to their music directly from this event.'
              />
            </div>


            <Dialog
              open={isAddingPerformers}
              scroll='body'
              onClose={closePerformerForm}
              fullWidth
              sx={{
                '& .MuiDialog-container': {
                  '& .MuiPaper-root': {
                    maxWidth: '500px'
                  }
                }
              }}
              keepMounted
              fullScreen={isSmallScreen}
              TransitionComponent={SlideTransition}
            >
              <PerformanceFormPerformers
                performers={values.performers}
                setFieldValue={setFieldValue}
                close={closePerformerForm}
              />
            </Dialog>




            <FormButton 
              type='submit'
              variant='contained'
              color='primary'
              disabled={isSubmitting}
            >
              {isSubmitting
                ? <ThreeDotProgress color='#fff' radius={5} />  
                : Boolean(performance) ? 'Update' : 'Create'
              }
            </FormButton>
          </div>
        </section> 
      </Form>
    </div>
  )
}





const EnhancedPerformanceForm = withFormik({
  mapPropsToValues: ({performance, venue}) => {
    const { 
      name, 
      start,  
      end, 
      price, 
      description,
      performing,
      website,
      tickets,
      location,
      address,
      coordinates,
      locationService,
      locationServiceId,
      tags,
      appleMusicLinks
    } = performance ? performance.attributes : {}
    

    const venueAttributes = venue ? venue.attributes : {}

    const venueName = venueAttributes.name
    const venueWebsite = venueAttributes.website
    const venueAddress = venueAttributes.address
    const venueCoordinates = venueAttributes.coordinates
    const venueLocationService = venueAttributes.locationService
    const venueLocationServiceId = venueAttributes.venueLocationServiceId
    
    const formattedStart = convertUTCDateToLocalDate(start)
    const formattedEnd = end ? convertUTCDateToLocalDate(end) : addHours(formattedStart, 1)


    return {
      name: name || '',
      date: formattedStart,
      start_time: formattedStart,
      end_time: formattedEnd,
      price: formatMoney(price).replace('$', ''),
      description: description || '',
      tags: tags || [],
      performing: performing || false,
      website: website || venueWebsite || '',
      tickets: tickets || venueWebsite || '',
      coordinates: coordinates ? coordinates.join(' ') : venueCoordinates ? venueCoordinates.join(' ') : '',
      location: location || venueName || '',
      location_service: locationService || venueLocationService || '',
      location_service_id: locationServiceId || venueLocationServiceId || '',
      address: address || venueAddress || defaultAddress,
      performers: [],
      apple_music_links: [...new Set(appleMusicLinks || [])]
    }
  },


  validationSchema: Yup.object().shape({
    name: Yup.string().required('required').max(150),
    date: Yup.date().required('required'),
    start_time: Yup.date().required('required'),
    end_time: Yup.date().required('required'),  
    location: Yup.string().required('required'),
    description: Yup.string().max(3500)
  }),


  handleSubmit: (values, formikBag) => {
    
    const {
      performance,
      close,
      create, 
      update, 
      showAlert,
      creatorId,
      creatorType,
      addPerformanceToBand,
      addPerformanceToVenue,
      addPerformanceToArtist
    } = formikBag.props


    const {setSubmitting, setErrors} = formikBag    


    const start = combineDateAndTime(values.date, values.start_time)
    const end = combineDateAndTime(values.date, values.end_time)


    if (end < start) {
      end.setDate(end.getDate() + 1)
    }


    // If the performance exists then update the record with the new values    
    if (performance) {
      
      const url = '/performances/' + performance.id

      const options = {headers: {'Content-Type': 'multipart/form-data'}}

      let submitValues = {...values}

      delete submitValues.date
      delete submitValues.start_time
      delete submitValues.end_time

      submitValues.start = format(start, "yyyy-M-dd'T'HH:mm:ss")
      submitValues.end = format(end, "yyyy-M-dd'T'HH:mm:ss")

      submitValues.tags = JSON.stringify(submitValues.tags)
      submitValues.address = JSON.stringify(submitValues.address)
      submitValues.apple_music_links = JSON.stringify(submitValues.apple_music_links)


      update(url, 'PERFORMANCE', submitValues, options).then(response => {
        showAlert('Performance updated', 'success')
        close()
      })
      .catch(error => {
        setSubmitting(false)
        setErrors(error.apiFormErrors(values))
        showAlert(error.apiMessage(), 'error')
      })

    // If the performance does not exist create a new record with the form values
    } else { 

      const isRepeating = values.repeats && values.repeats !== 'never'

      let url = '/' + creatorType + 's/' + creatorId
      
      url += isRepeating ? '/repeating_performance' : '/performances'
      

      const form = new FormData()

      if (values.image) form.append('image', values.image)
      if (values.video) form.append('video', values.video)

      form.append('name', values.name)
      form.append('start', format(start, "yyyy-M-dd'T'HH:mm:ss"))
      form.append('end', format(end, "yyyy-M-dd'T'HH:mm:ss"))
      form.append('price', values.price)
      form.append('description', values.description)
      form.append('website', values.website)
      form.append('tickets', values.tickets)
      form.append('location', values.location)
      form.append('coordinates', values.coordinates)
      form.append('location_service', values.location_service)
      form.append('location_service_id', values.location_service_id)
      form.append('address', JSON.stringify(values.address))
      form.append('tags', JSON.stringify(values.tags))
      form.append('apple_music_links', JSON.stringify(values.apple_music_links))

      if (values.repeats) {
        form.append('repeats', values.repeats)
        form.append('repeat_end', format(values.repeat_end, "yyyy-M-dd'T'HH:mm:ss"))
      }

      if (values.performers.length > 0) {
        form.append('performers', JSON.stringify(values.performers))
      }
      
      if (values.previous_performance_image) {
        form.append('previous_performance_image', values.previous_performance_image)
      }


      create(url, 'PERFORMANCE', form).then(response => {
        if (creatorType === 'band') addPerformanceToBand(creatorId, response.data)
        if (creatorType === 'venue') addPerformanceToVenue(creatorId, response.data)
        if (creatorType === 'artist') addPerformanceToArtist(creatorId, response.data)

        showAlert('Performance created', 'success')
        close()
      })
      .catch(error => {
        setSubmitting(false)
        setErrors(error.apiFormErrors(values))
        showAlert(error.apiMessage(), 'error')
      })
    }
  }
})(PerformanceForm)





const makeMapStateToProps = () => {
  const getVenueById = makeGetVenueById()
  const getPerformanceById = makeGetPerformanceById()


  const mapStateToProps = (state, props) => {
    
    const id = props.performanceId

    const {creatorId, creatorType} = props
    
    const venue = (creatorType === 'venue') ? getVenueById(state, creatorId) : null



    return {
      venue: venue,
      performance: id ? getPerformanceById(state, id) : null
    }
  }

  return mapStateToProps
}



const actions = { 
  create, 
  update, 
  showAlert, 
  addPerformanceToArtist,
  addPerformanceToBand,
  addPerformanceToVenue
}

export default connect(makeMapStateToProps, actions)(EnhancedPerformanceForm)





