import {crudAPI} from '../apis'

import {
  addTracksToBand,
  addTracksToArtist,
  addTracksToPerformance, 
  setFetchedAssociations 
} from './relationshipActions'



export function close() {
  return dispatch => {
    dispatch({type: 'CLOSE_AUDIO_PLAYER'})
  }
}


export function play() {
  return dispatch => {
    dispatch({type: 'PLAY_AUDIO'})
  }
}


export function playNewTrack(track, callerID, callerType) {
  return dispatch => {
    dispatch({
      type: 'PLAY_NEW_TRACK', 
      payload: {
        track,
        callerID,
        callerType
      }
    })
  }
}


export function pause() {
  return dispatch => {
    dispatch({type: 'PAUSE_AUDIO'})
  }
}


export function next() {
  return dispatch => {
    dispatch({type: 'NEXT_TRACK'})
  }
}


export function previous() {
  return dispatch => {
    dispatch({type: 'PREVIOUS_TRACK'})
  }
}


// Load new set of tracks and play
export function queue(tracks, callerID, callerType) {
  return dispatch => {
    dispatch({
      type: 'QUEUE_TRACKS',
      payload: {
        tracks, 
        callerID, 
        callerType
      }
    })
  }
}


// Append a new set of tracks to the current queuq or if nothing is playing
// or the caller is new, then load tracks and play them
export function enqueue(tracks, callerID, callerType, links = {}) {
  return dispatch => {
    dispatch({
      type: 'ENQUEUE_TRACKS', 
      payload: {tracks, links, callerID, callerType}})
  }
}


export function enqueueMapTracks(tracks) {
  return dispatch => {
    dispatch({type: 'ENQUEUE_MAP_TRACKS', payload: tracks})
  }
}



// Get the tracks, queue them, and then play
export function getQueueAndPlayTracks(url, id, type) {
  const uppercasedType = type.toUpperCase()

  return dispatch => {
    return crudAPI.index(url).then(response => {
      const tracks = response.data.data

      dispatch(setFetchedAssociations(uppercasedType, id, 'tracks'))

      if (tracks.length) {
        dispatch({type: 'TRACKS_INDEX_SUCCESS', payload: response.data})
        dispatch(queue(tracks, id, type))

        if (type === 'artist') {
          dispatch(addTracksToArtist(id, tracks))
        } else if (type === 'band') {
          dispatch(addTracksToBand(id, tracks))
        } else if (type === 'performance') {
          dispatch(addTracksToPerformance(id, tracks))
        }

        return 'success'
      }
    })
  }
}


// Get and queue the tracks but don't play
export function getAndQueueTracks(url, id, type) {
  const uppercasedType = type.toUpperCase()

  return dispatch => {
    return crudAPI.index(url).then(response => {
      const tracks = response.data.data

      dispatch(setFetchedAssociations(uppercasedType, id, 'tracks'))

      if (tracks.length) {
        dispatch({type: 'TRACKS_INDEX_SUCCESS', payload: response.data})
        dispatch(enqueue(tracks, id, type))

        if (type === 'artist') {
          dispatch(addTracksToArtist(id, tracks))
        } else if (type === 'band') {
          dispatch(addTracksToBand(id, tracks))
        } else if (type === 'performance') {
          dispatch(addTracksToPerformance(id, tracks))
        }

        return 'success'
      }
    })
  }
}




export function replaceHyperlinkTrackInQueue(link, tracks, navigateTo) {
  return dispatch => {
    dispatch({
      type: 'REPLACE_HYPERLINK_TRACK_IN_QUEUE', 
      payload: {link, tracks, navigateTo}
    })
  }
}











