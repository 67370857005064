import {createSelector} from 'reselect'
import {pick} from 'lodash'


const selectVenues = state => state.venues.byId
const selectPerformances = state => state.performances.byId


// Status
export const selectSearchStatus = state => state.search.status


// Results
export const selectVenueSearchResults = state => state.search.venue.results
export const selectPerformanceSearchResults = state => state.search.performance.results


// Links
export const selectVenueSearchLinks = state => state.search.venue.links
export const selectPerformanceSearchLinks = state => state.search.performance.links




export const searchVenuesSelector = createSelector(
  [selectVenueSearchResults, selectVenues], (ids, byID) => pick(byID, ids)
)


export const searchPerformancesSelector = createSelector(
  [selectPerformanceSearchResults, selectPerformances], (ids, byID) => {
    return pick(byID, ids)
  }
)



export const makeSelectSearchVenues = () => {
  return createSelector([selectVenueSearchResults, selectVenues], (ids, byID) => {
    return pick(byID, ids)
  })
}



export const makeSelectSearchPerformances = () => {
  return createSelector([selectPerformanceSearchResults, selectPerformances], (ids, byID) => {
    return pick(byID, ids)
  })
}



export const makeSelectSortedSearchPerformances = () => {
  return createSelector([selectPerformanceSearchResults, selectPerformances], (ids, byID) => {
    const events = pick(byID, ids)


    return Object.values(events).sort((a, b) => {
      const date1 = new Date(a.attributes.start)
      const date2 = new Date(b.attributes.start)

      return date1 - date2
    })
  })
}



