import {shuffle} from 'lodash'
 
// The id represents the object that modified the audio player. For instance,
// if tracks were loaded from a MapListItem, then the id represents the id of
// the performance that was clicked. 
const INITIAL_STATE = {
  id: '',
  queue: [],
  position: 0,
  isOpen: false,
  links: {},
  callerID: '',
  callerType: '',
  isPlayRequested: false
}



 const playerReducer = (state = INITIAL_STATE, action) => {
  let link
  let index
  let tracks


  switch (action.type) {
    case 'PLAY_NEW_TRACK':
      return {
        ...state,
        queue: [action.payload.track],
        position: 0,
        links: {},
        isOpen: true,
        callerID: action.payload.callerID,
        callerType: action.payload.callerType,
        isPlayRequested: true
      }
    case 'NEXT_TRACK':
      index = state.position + 1

      return {
        ...state,
        position: index < state.queue.length ? index : 0
      }
    case 'PLAY_TRACK':
      return {
        ...state,
        queue: [action.payload, ...state.queue]
      }
    case 'PREVIOUS_TRACK':
      index = state.position - 1

      return {
        ...state,
        position: index > -1 ? index : state.queue.length - 1
      }
    case 'PLAY_AUDIO':
      return {
        ...state,
        isOpen: true, 
        isPlayRequested: true
      }
    case 'PAUSE_AUDIO':
      return {
        ...state, 
        isPlayRequested: false
      }
    case 'QUEUE_TRACKS':
      tracks = [...action.payload.tracks]

      return { 
        ...state,
        isOpen: true,
        queue: tracks,
        callerID: action.payload.callerID,
        callerType: action.payload.callerType,
        isPlayRequested: true

      }
    case 'ENQUEUE_TRACKS':
      // If the caller is the same then append the new tracks...
      if (action.payload.callerID === state.callerID) {
        tracks = [...state.queue, ...action.payload.tracks]

        return {
          ...state,
          queue: tracks,
          links: action.payload.links
        }
      }


      // Otherwise act as QUEUE_TRACKS     
      tracks = shuffle(action.payload.tracks)

      return {
        ...state,
        queue: tracks,
        links: action.payload.links,
        isOpen: true,
        callerID: action.payload.callerID,
        callerType: action.payload.callerType,
        isPlayRequested: true
      }
    case 'ENQUEUE_MAP_TRACKS': 
      return {
        ...state,
        queue: [...state.queue, ...action.payload],
        callerID: 'map',
        callerType: 'map'
      }
    // This replaces the currently queued track if its not an actual track
    // for instances when a spotify link or apple music link is queued up
    case 'REPLACE_HYPERLINK_TRACK_IN_QUEUE':
      tracks = action.payload.tracks

      tracks = tracks.map(track => {
        track.navigateTo = action.payload.navigateTo
        return track
      })

      // Get the link that will be used to find the member in the array to replace 
      link = action.payload.link

      // Index of element that needs to be replaced
      index = state.queue.findIndex(e => e.link === link)

      
      // Ensure that the link was found and there are tracks
      if (index > -1 && tracks.length) {
        let newQueue = [...state.queue]

        // Update the link element with a track
        newQueue[index] = tracks[0]
        // Append the rest of the tracks to the end of the array
        newQueue = newQueue.concat(tracks.splice(1))


        return {
          ...state,
          queue: newQueue
        }
      }
      
      return state
    case 'REMOVE_HYPERLINK_TRACK_IN_QUEUE':
      // Get the link that will be used to find the member in the array to replace 
      link = action.payload

      // Index of element that needs to be replaced
      index = state.queue.findIndex(e => e.link === link)


      if (index > -1) {
        return {
          ...state, 
          queue: state.queue.toSpliced(index, 1),
          position: Math.min(state.queue.length - 1, state.queue.index)
        }
      } else {
        return state
      }
    case 'CLOSE_AUDIO_PLAYER':
      return INITIAL_STATE
    default:
      return state
  }
}




export default playerReducer



