import {createSelector} from 'reselect'


export const selectUserId = state => state.user.id
export const selectUserAttributes = state => state.user.attributes
export const selectUserSettings = state => state.user.settings
export const selectUserAccountType = state => state.user.account.type
export const selectUserAccountId = state => state.user.account.id
export const selectUserAccountRelationships = state => state.user.account.relationships
export const selectUserLocation = state => state.user.location

// Memoized selector for user associations
export const selectUserAssociations = createSelector(
  [selectUserId, selectUserAccountType, selectUserAccountId, selectUserAccountRelationships],
  (userId, accountType, accountId, relationships) => {
    const associations = { user: userId }

    if (accountType === 'artist' || accountType === 'venue') {
      associations[accountType] = accountId

      if (accountType === 'artist' && relationships?.bands) {
        associations.bands = relationships.bands
      }
    }

    return associations
  }
)

// Memoized selector for user coordinates
export const selectUserCoordinates = createSelector(
  [selectUserLocation],
  location => ({
    lat: location.lat,
    lng: location.lng
  })
)

// Memoized selector for user details
export const userSelector = createSelector(
  [selectUserId, selectUserAttributes],
  (id, attributes) => ({
    id,
    attributes,
    type: 'user'
  })
)
