import {useEffect, useRef} from 'react'

import {useSelector, useDispatch} from 'react-redux'

import {isEmpty} from 'lodash'

import {
  setMarkers, 
  addMarkers,
  resetClickedMarker,
  setClickedMarkerData
} from '../actions'

import {MarkerClusterer} from '@googlemaps/markerclusterer'



import {
  selectUserMarker,
  selectIsUserLocated,
  selectMarkerQueue,
  selectVenueMarkers,
  selectUserCoordinates,

  selectSearchTypeFilter,
  selectClickedMarkerData,
  selectPerformanceMarkers,

  selectVenueSearchLinks,
  selectPerformanceSearchLinks
} from '../selectors'


import {
  icons, 
  defaultIconSize,
  createMapMarker,
  createVenueInfoWindow,
  createPerformanceMapInfoWindow
} from '../helpers'





function createMarkers(map, items, clickCallback) {
  const markers = {}
  const mapBounds = map.getBounds()


  // For each item, create an info window and marker
  for (const item of items) {
    const data = {
      ...item, 
      isSelected: false
    }

    const coordinates = {
      lat: item.attributes.coordinates[1],
      lng: item.attributes.coordinates[0]
    }

    // Create new marker
    const marker = createMapMarker(coordinates, item.type, data)


    // Only add the marker if its within the bounds of the viewport
    if (mapBounds.contains(marker.getPosition())) {
      marker.setMap(map)
    }



    // Handle what happens when user clicks a marker
    marker.addListener('click', _ => {
      // updateMarkerAppearance(marker, !marker.data.isSelected)
      clickCallback(item)
    })


    // Add new markers and info windows to collection
    markers[item.id] = marker
  }



  // Add a marker clusterer to manage the markers.
  // new MarkerClusterer({markers: Object.values(markers), map})
  return markers
}




function updateMarkerAppearance(marker, isSelected) {
  const type = marker.data.type
  const icon = isSelected 
    ? 'selected' + type.charAt(0).toUpperCase() + type.slice(1) 
    : type
  
  const size = isSelected 
    ? {width: defaultIconSize.width + 7, height: defaultIconSize.height + 7} 
    : defaultIconSize

  marker.setIcon({
    url: icons[icon],
    anchor: new window.google.maps.Point(size.width / 2, size.height),
    scaledSize: new window.google.maps.Size(size.width, size.height)
  })
 
  marker.setZIndex(isSelected ? 10 : (type === 'venue' ? 5 : 7))
  
  // Update the marker data to reflect its current selection state
  marker.data = {
    ...marker.data,
    isSelected
  }
}





export function useCreateMapMarkers(map) {

  const dispatch = useDispatch()

  const markerQueue = useSelector(selectMarkerQueue)

  const infoWindow = useRef()
  const selectedMarker = useRef()

  // User
  const userMarker = useSelector(selectUserMarker)
  const isUserLocated = useSelector(selectIsUserLocated)
  const userCoordinates = useSelector(selectUserCoordinates)


  const clickedMarkerData = useSelector(selectClickedMarkerData)

  // Venues
  const venueLinks = useSelector(selectVenueSearchLinks)
  const venueMarkers = useSelector(selectVenueMarkers)


  // Performances
  const performanceLinks = useSelector(selectPerformanceSearchLinks)
  const performanceMarkers = useSelector(selectPerformanceMarkers)


  const searchTypes = useSelector(selectSearchTypeFilter)



  // Load the necessary libraries if they are not loaded already
  useEffect(() => {
    async function loadLibraries() {
      // eslint-disable-next-line no-unused-vars
      const {Map, InfoWindow} = await window.google.maps.importLibrary('maps')
      // eslint-disable-next-line no-unused-vars
      const {AdvancedMarkerElement} = await window.google.maps.importLibrary('marker')
    }

    loadLibraries()
  }, [])




  // This fires when the map goes from panning and zooming to becoming
  // idle. Once the map becomes idle, this effect checks to see if 
  // there are markers that should be added based on if the marker location
  // is inside the user's viewport.
  useEffect(() => {
    let idleListener


    if (map) {
      idleListener = map.addListener('idle', () => {
        const mapBounds = map.getBounds()


        if (searchTypes.includes('venues')) {
          Object.values(venueMarkers).forEach((marker, index) => {
            if (mapBounds.contains(marker.getPosition())) {
              window.setTimeout(() => {
                marker.setMap(map)
              }, Math.ceil(index /10) * 100)
            }
          })
        }



        if (searchTypes.includes('performances')) {
          Object.values(performanceMarkers).forEach((marker, index) => {
            if (mapBounds.contains(marker.getPosition())) {
              window.setTimeout(() => {
                marker.setMap(map)
              }, Math.ceil(index /10) * 100)
            }
          })
        }
      })
    }


    return () => {
      if (idleListener) {
        window.google.maps.event.removeListener(idleListener)
      }
    }
  }, [
    map, 
    searchTypes,
    venueMarkers, 
    performanceMarkers
  ])




  // Adds new markers
  useEffect(() => {
    if (map) {
      Object.keys(markerQueue).forEach(type => {

        const items = markerQueue[type]

        const singularType = type.slice(0, -1)

        // Current page of pagination links
        const linksPage = type === 'performances' ? performanceLinks.page : venueLinks.page


        if (items.length) {
          // The callback function that gets called when user clicks a marker
          const clickCallback = item => {
            dispatch(setClickedMarkerData(item))
          }

          // Add markers to the map
          const markers = createMarkers(map, items, clickCallback)


          // Clear the queue
          dispatch({type: 'CLEAR_' + singularType.toUpperCase() + '_MAP_MARKER_QUEUE'})


          // Save the markers to the store
          // -----------------------------
          // If this is a new search then remove the previous markers and set the new ones...
          if (linksPage === 1 && isEmpty(performanceMarkers)) {
            dispatch(setMarkers(markers, singularType.toUpperCase()))
          // Otherwise add the new markers to dictionary of existing markers
          } else {
            dispatch(addMarkers(markers, singularType.toUpperCase()))
          }
        }
      })
    }
  }, [
    map,
    dispatch,
    markerQueue,
    venueLinks.page,
    venueLinks.first_url,
    performanceLinks.page,
    performanceLinks.first_url,
    performanceMarkers
  ])





  // Add the user marker and center the map to the users location.
  // Once the user marker is added, then the map is ready to add 
  // other markers and is avaiable for use.
  useEffect(() => {
    if (map && isUserLocated && !userMarker) {
      try {
        const lat = userCoordinates.lat
        const lng = userCoordinates.lng

        // Check that coordinates are valid
        const latlng = new window.google.maps.LatLng(lat, lng)

        const marker = new window.google.maps.Marker({
          map,
          icon: {url: 'data:image/svg+xml;utf-8, ' + icons.user},
          position: latlng
        })

        // Save a reference to the user marker 
        dispatch({type: 'SET_USER_MARKER', payload: marker})

      } catch {
        console.log('TODO: Handle error in useCreateMapMarkers - 0')
      }
    }
  }, [
    map, 
    dispatch, 
    userMarker, 
    isUserLocated, 
    userCoordinates.lat, 
    userCoordinates.lng
  ])





  // The effect does two things:
  // 1. Creates a new info window for the marker that's clicked
  // 2. Changes the current clicked markers state back to the original state
  useEffect(() => {
    // If a marker's clicked data is empty, then return
    if (isEmpty(clickedMarkerData)) {
      return
    } 


    const {id, type} = clickedMarkerData

    // Currently clicked marker
    const marker =
      type === 'venue'
        ? venueMarkers[id]
        : performanceMarkers[id]


    if (marker) {
      if (selectedMarker.current && selectedMarker.current === marker) {
        return
      }


      // If there's a previously selected marker, reset its appearance
      if (selectedMarker.current && selectedMarker.current !== marker) {
        updateMarkerAppearance(selectedMarker.current, false)
      }


      // Highlight the newly selected marker
      updateMarkerAppearance(marker, true)


      // If an info window is open, close it
      if (infoWindow.current) {
        infoWindow.current.close()
        infoWindow.current = null
      }


      infoWindow.current =
        type === 'venue'
          ? createVenueInfoWindow(clickedMarkerData)
          : createPerformanceMapInfoWindow(clickedMarkerData)


      infoWindow.current.open({map, anchor: marker})

      // Add a listener to reset the marker when the info window is closed
      window.google.maps.event.addListener(infoWindow.current, 'closeclick', () => {
        updateMarkerAppearance(marker, false)
        dispatch(resetClickedMarker())
        selectedMarker.current = null // Clear the selected marker
      })

      // Update the reference to the newly selected marker
      selectedMarker.current = marker
    }
  }, [
    map,
    dispatch,
    venueMarkers,
    performanceMarkers,
    clickedMarkerData
  ])
}




