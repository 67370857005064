export function convertAppleMusicTrackToCkordFormat(track, composerID, composerType = 'Artist', link) {
  const artworkURL = constructArtworkUrl(track.attributes.artwork.url)

  return {
    id: track.id,
    type: 'track',
    attributes: {
      name: track.attributes.name,
      video: null,
      audio: track.attributes.previews[0].url,
      previewAudio: track.attributes.previews[0].url,
      composer: track.attributes.composerName,
      composerId: composerID || track.artists?.[0].id,
      composerType: composerType,
      image: artworkURL,
      imageDerivatives: {
        small: artworkURL,
      },
      tags: [],
      appleMusicLink: track.attributes.url,
      isAppleMusicTrack: true
    }
  }
}




export function convertAppleMusicTracksToCkordFormat(tracks, composerID, composerType = 'Artist') {
  return tracks.filter(track => {
    const previews = track?.attributes?.previews

    if (previews) {
      return Boolean(previews[0].url)
    } else {
      return false
    }
  })
  .map(track => {
    return convertAppleMusicTrackToCkordFormat(track, composerID, composerType)
  })
}



export function convertAppleMusicLinkToTrackForEvent(link, event) {
  return {
    id: event.id,
    type: 'track',
    link: link,
    attributes: {
      isAppleMusicTrack: true,
      name: '',
      video: null,
      audio: null,
      previewAudio: '',
      composer: '',
      composerId: event.id,
      composerType: 'Event',
      image: '',
      imageDerivatives: {
        small: ''
      },
      tags: []
    }
  }
}



function constructArtworkUrl(baseUrl, width = 300, height = 300) {
  return baseUrl.replace('{w}', width).replace('{h}', height)
}


export default convertAppleMusicTracksToCkordFormat