
export const selectMap = state => state.map.map
export const selectMarkerQueue = state => state.map.markers.queue
export const selectVenueMarkers = state => state.map.markers.venues
export const selectPerformanceMarkers = state => state.map.markers.performances
export const selectClickedMarkerData = state => state.map.markers.clickedMarkerData


// User location
export const selectUserMarker = state => state.map.markers.user
export const selectIsUserLocated = state => state.user.location.isLocated
