import {useEffect} from 'react'

import {useSelector, useDispatch} from 'react-redux'

import {search, mergeSearch, clearMarkersFor} from '../actions'

import {selectVenueFilters, selectSearchStatus} from '../selectors'




export function useSearchVenues() {

  const dispatch = useDispatch()
  
  const filters = useSelector(selectVenueFilters)

  const status = useSelector(selectSearchStatus)



  useEffect(() => {
    if (status === 'requested' || status === 'mergeRequested') {
      const data = {} 

      // Add Location to search filters
      const {lat, lng, radius} = filters.location


      if (!radius) return


      data['location'] = {lat, lng, radius}


      // Add tags if any are preent
      if (filters.tags) {
        data['tags'] = filters.tags
      }



      if (status === 'requested') {
        dispatch(clearMarkersFor('VENUE'))
        dispatch(search('search/venues', data, 'VENUE'))
      }


      if (status === 'mergeRequested') {
        dispatch(mergeSearch('search/venues', data, 'VENUE'))
      }
    }
  }, [filters, status, dispatch])

}



export default useSearchVenues