import React, {useEffect, useState, useRef} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import * as Yup from 'yup'
import {withFormik, Form, Field} from 'formik'

import {makeGetBandById} from '../../selectors'

import {defaultAddress, defaultLinks} from '../../helpers'

import {sentenceCase, snakeCaseObject, formatInfoPageAddress} from '../../utils'

import {create, update, showAlert, addBandsToArtist} from '../../actions'


import YouTubeIcon from '@mui/icons-material/YouTube'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'

import {FaXTwitter} from 'react-icons/fa6'
import {IoLogoTiktok} from 'react-icons/io5'
import {SiApplemusic} from 'react-icons/si'
import {PiSpotifyLogoFill} from 'react-icons/pi'


import IconButton from '@mui/material/IconButton'


import { 
  Errors,
  TagField,
  AspectBox,
  FormTitle,
  FormButton,
  ImageField,
  CloseButton,
  FormikTextField,
  FormikPhoneNumberField,
  GooglePlacesField,
  ThreeDotProgress
} from '../generics'






const cssStyles = ({isExtraSmallScreen}) => ({
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${isExtraSmallScreen ? '40px' : '60px'};

    form {
      width: 100%;
      max-width: 420px;
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      flex-direction: column;
      padding-top: 20px;
      gap: 40px;

      section {
        width: 100%;
        padding: 0px;
      }

      section:last-of-type {
        max-width: 420px;
      }
    }
  `,
  imageContainer: css`
    width: 100%;
    max-width: 420px;
  `,
  fieldContainer: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 420px;
  `,
  addressContainer: css`
    background-color: rgba(62, 166, 255, 0.1);
    padding: 15px;
    border-radius: 5px;
    border: 2px solid #dedede;
  `,
  linksContainer: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
  `,
  linkIconsContainer: css`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `,
  fieldLabel: css`
    h4 {
      margin-top: 15px;
      margin-bottom: 0;
    }

    p {
      margin-bottom: 10px;
    }
  `
})





const linkIcons = {
  apple: <SiApplemusic />,
  spotify: <PiSpotifyLogoFill />,
  instagram: <InstagramIcon />,
  tiktok: <IoLogoTiktok />,
  youtube: <YouTubeIcon />,
  x: <FaXTwitter />,
  facebook: <FacebookIcon />
}




const BandForm = props => {

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  const styles = cssStyles({isExtraSmallScreen})

  const linksRef = useRef()

  const [link, setLink] = useState('apple')
  


  const {
    band,
    close,
    errors,
    values,
    touched,
    handleBlur,
    isSubmitting,
    setFieldValue,
    setFieldError
  } = props


  const image = band?.attributes.image

  const isAddressEmpty = Object.values(values.address).every(field => !Boolean(field))




  useEffect(() => {
    if (values.location === '' && touched.location) {
      setFieldValue('address', defaultAddress)
    }
  }, [values.location, touched.location, setFieldValue])




  const trimValue = (value, field) => e => {
    handleBlur(e)


    if (typeof value === 'string' || value instanceof String) {
      const trimmed = value.trim()

      if (trimmed !== value && values[field]) {
        setFieldValue(field, trimmed)
      }
    }
  }



  const selectLink = label => () => {
    setLink(label)

    if (linksRef.current) {
      linksRef.current.focus()
    }
  }



  const label = () => {
    switch (link) {
      case 'apple':
        return 'Apple Music' 
      case 'spotify':
        return 'Spotify'
      case 'instagram':
        return 'Instagram'
      case 'x':
        return 'X (previously Twitter)'
      case 'youtube':
        return 'YouTube'
      case 'facebook':
        return 'Facebook'
      case 'tiktok':
        return 'TikTok'
      default: 
        return ''
    }
  }



  return (
    <div css={styles.root}>
      <CloseButton onClick={close} />

      <Form autoComplete='off'>

        <section>
          <div css={styles.imageContainer}>
            <AspectBox>
              <ImageField 
                image={image} 
                fieldValue={values.image}
                setFieldValue={setFieldValue} 
                setFieldError={setFieldError}
              />
            </AspectBox>
          </div>
        </section>



        <section>
          <FormTitle title={Boolean(band) ? 'Edit Band' : 'Create Band'} />

          <Errors errors={errors?.unmatched} />


          <div css={styles.fieldContainer}>
            <Field
              name='name' 
              label='Band Name'
              variant='filled'
              fullWidth 
              component={FormikTextField}
              autoComplete='off'
              onBlur={trimValue(values.name, 'name')}
            />


            <Field
              fullWidth 
              name='location'
              variant='filled'
              component={GooglePlacesField}
              placeholder='Nashville, New York, Austin...'
            />


            <div css={styles.addressContainer}>
              <p>
                {isAddressEmpty
                  ? `Once a location is selected from the dropdown above, how it will be
                     displayed on your band page will be shown here.`
                  : formatInfoPageAddress(values.address)
                }
              </p>
            </div>


            <Field
              name='website'
              label='Website'
              variant='filled'
              fullWidth
              component={FormikTextField}
              placeholder='Website URL'
              onBlur={trimValue(values.website, 'website')}
            />


            <Field
              name='email'
              label='Contact Email'
              variant='filled'
              fullWidth
              component={FormikTextField}
              placeholder='contact@ckord.com'
            />


            <Field 
              name='phoneNumber' 
              variant='filled'
              component={FormikPhoneNumberField} 
            />


            <Field
              variant='filled'
              component={FormikTextField}
              name='bio'
              label='Bio'
              fullWidth
              multiline={true}
              rows={12}
              onBlur={trimValue(values.bio, 'bio')}
              helperText={
                new Intl.NumberFormat().format(3500 - values.bio.length).toString() + ' characters left'
              }
            />


            <div css={styles.linksContainer}>
              <div css={styles.fieldLabel}>
                <h4>Links</h4>
                <p>Click the icons below to add a link</p>
              </div>


              <div css={styles.linkIconsContainer}>
                {Object.keys(linkIcons).map(key => (
                  <IconButton
                    key={key}
                    onClick={selectLink(key)}
                    sx={{
                      border: link === key ? '2px solid #000' : '2px solid #E0E3E7'
                    }}
                  >
                    {linkIcons[key]}
                  </IconButton>
                ))}
              </div>


              <Field
                innerRef={linksRef}
                name={'links.' + link}
                error={touched.links?.[link] && Boolean(errors?.links?.[link])}
                label={label()}
                fullWidth
                variant='filled'
                component={FormikTextField}
                helperText={(touched.links?.[link] && errors?.links?.[link])}
                placeholder={sentenceCase(link) + ' URL'}
              />
            </div>



            <Field
              name='tags'
              label='Tags'
              variant='filled'
              component={TagField}
              placeholder='Max of 5'
              fullWidth
            />


            <FormButton 
              type='submit' 
              variant='contained'
              color='primary'
              disabled={isSubmitting}
            >
              {isSubmitting
                ? <ThreeDotProgress color='#fff' radius={5} />  
                : Boolean(band) ? 'Update' : 'Create' 
              }
            </FormButton>

          </div>
        </section>
      </Form>
    </div>
  )
}






const EnhancedBandForm = withFormik({
  mapPropsToValues: ({ band }) => {
    const { 
      name,
      bio,
      tags,
      website,
      email,
      phoneNumber,
      links,
      location,
      address,
      coordinates
    } = band ? band.attributes : {}


    return {
      name: name || '',
      bio: bio || '',
      tags: tags || [],
      website: website || '',
      email: email || '',
      phoneNumber: phoneNumber || '',
      location: location || '',
      coordinates: coordinates ? coordinates.join(' ') : '',
      address: address || defaultAddress,
      links: {...defaultLinks, ...links}
    }
  },


  validationSchema: Yup.object().shape({
    name: Yup.string().required('required').max(60),
    bio: Yup.string().max(3500),
    email: Yup.string().email('Invalid email').max(255),
    links: Yup.object().shape({
      spotify: Yup.string().matches(/spotify.com\//i, 'must be valid Spotify URL'),
      x: Yup.string().matches(/twitter.com\//i, 'must be valid Twitter URL'),
      youtube: Yup.string().matches(/youtube.com\//i, 'must be valid YouTube URL'),
      instagram: Yup.string().matches(/instagram.com\//i, 'must be valid Instagram URL'),
      facebook: Yup.string().matches(/facebook.com\//i, 'must be valid Facebook URL'),
      tiktok: Yup.string().matches(/tiktok.com\//i, 'must be valid TikTok URL'),
    })
  }),


  handleSubmit: (values, formikBag) => {
    const {
      band,
      close, 
      create, 
      update,
      showAlert,
      creatorId,
      creatorType, 
      addBandsToArtist
    } = formikBag.props


    const {setSubmitting, setErrors} = formikBag



    // Add the country code to the phone number if the phone number is 
    // a US number. This is done because on the backend, certain phone numbers
    // (e.g. (718) 224-2923) throw an error if they don't have a country code    
    if (values.phoneNumber && values.phoneNumber.charAt(0) === '(') {
      values.phoneNumber = '+1 ' + values.phoneNumber
    }


     // If the band exists then update the record with the new values
    if (band) {

      const url = '/bands/' + band.id

      const options = {headers: {'Content-Type': 'multipart/form-data'}}

      let data = snakeCaseObject(values)

      data.tags = JSON.stringify(data.tags)
      data.links = JSON.stringify(data.links)
      data.address = JSON.stringify(data.address)



      update(url, 'BAND', data, options).then(response => {
        showAlert('Band updated', 'success')
        close()
      })
      .catch(error => {
        setSubmitting(false)
        setErrors(error.apiFormErrors(values))
      })


    // If the artist does not exist create a new record with the form values
    } else { 

      const url = '/' + creatorType + 's/' + creatorId + '/bands'

      const form = new FormData()

      
      if (values.image) {
        form.append('image', values.image)
      }


      form.append('name', values.name)
      form.append('website', values.website)
      form.append('email', values.email)
      form.append('phone_number', values.phoneNumber)
      form.append('bio', values.bio)
      form.append('website', values.website)
      form.append('location', values.location)
      form.append('coordinates', values.coordinates)
      form.append('tags', JSON.stringify(values.tags))
      form.append('address', JSON.stringify(values.address))
      form.append('links', JSON.stringify(values.links))


      create(url, 'BAND', form).then(response => {               
        addBandsToArtist(creatorId, response.data)
        showAlert('Band created', 'success')
        close()
      })
      .catch(error => {
        setSubmitting(false)
        setErrors(error.apiFormErrors(values))
      })
    }
  }
})(BandForm)



const makeMapStateToProps = () => {
  const getBandById = makeGetBandById()

  const mapStateToProps = (state, props) => {
    const id = props.bandId

    return {
      band: id ? getBandById(state, id) : null
    }
  }

  return mapStateToProps
}



const actions = {create, update, showAlert, addBandsToArtist}


export default connect(makeMapStateToProps, actions)(EnhancedBandForm)





