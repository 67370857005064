import {icons} from '../helpers/google'


// Marker animation options: BOUNCE, DROP


export const defaultIconSize = {
  width: window.innerWidth <= 800 ? 30 : 35,
  height: (window.innerWidth <= 800 ? 30 : 35) * 316 / 273
}



export function createMarkerIcon(url) {
  const {width, height} = defaultIconSize

  return {
    url: url,
    anchor: new window.google.maps.Point(width / 2, height),
    scaledSize: new window.google.maps.Size(width, height),
    flat: true
  }
}




export function createMapMarker(coordinates, type, data) {
  const zIndex = (type === 'venue' ? 7 : 5)
  const icon = createMarkerIcon(icons[type])

  const marker = new window.google.maps.Marker({
    zIndex, 
    icon: icon,
    position: coordinates, 
    animation: window.google.maps.Animation.DROP,
    flat: true,
    data
  })

  return marker
}