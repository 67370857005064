export const defaultLinks = {
  apple: '',
  spotify: '', 
  instagram: '', 
  x: '', 
  youtube: '', 
  facebook: '',
  tiktok: ''
}

export default defaultLinks