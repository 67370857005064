import React from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {Link} from 'react-router-dom'

import {day, dayOfMonth, month, formatInfoPageAddress} from '../../utils'

import Avatar from '@mui/material/Avatar'




const styles = {
  link: css`
    width: 100%;
    display: flex;
    wrap: nowrap;
    gap: 20px;
    align-items: center;
    text-decoration: none;
    color: #000;
  `,
  center: css`
    flex-grow: 1;
    margin-bottom: 5px;

    p {
      font-size: 0.9rem;
      letter-spacing: -0.01em;
      line-height: 1.1;
    }

    span {
      text-transform: uppercase;
      font-size: 0.75rem;
      color: rgb(62, 166, 255);
      font-weight: 600;
      line-height: 1;
    }
  `,
  date: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 55px;
    min-width: 55px;
    border-radius: 7px;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.2;
    background-color: #e9e9e9;
    color: #000;

    h3 {
      font-size: 1.4rem;
      font-weight: 900;
      line-height: 1.1;
      letter-spacing: -0.03em;
    }

    span {
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: 500;
      color: #000;
    }
  `
}



const PerformanceSearchResult = props => {

  const {close, performance} = props
  
  const {
    start, 
    name, 
    address, 
    image, 
    imageDerivatives, 
    externalImageUrl
  } = performance.attributes



  return (
    <Link onClick={close} to={'/performances/' + performance.id} css={styles.link}>
      <Avatar
        alt={name} 
        src={imageDerivatives.small || image || externalImageUrl}
        sx={{width: 50, height: 70, objectFit: 'cover'}}
        variant='rounded' 
      />


      <div css={styles.center}>
        <span>{formatInfoPageAddress(address)}</span>

        <p>{name}</p>
      </div>

      <div css={styles.date}>
        <span>{day(start)}</span>

        <h3>{dayOfMonth(start)}</h3>

        <span>{month(start)}</span>
      </div> 
    </Link>
  )
}


export default PerformanceSearchResult






