import { combineReducers } from 'redux'
import { filter, omit, map, uniq, get } from 'lodash'
import {
  mergeObjects,
  mergeRelationships,
  mergeLinks,
  removeDataKeyFromRelationships,
  compareAndAddNewValues
} from '../utils'
import normalize from 'json-api-normalizer'



const byId = (state = {}, action) => {
  let id = ''

  switch (action.type) {
    case 'ARTISTS_INDEX_SUCCESS':
      if (action.payload.data.length) {
        let normalizedResponse = normalize(action.payload).artist
        normalizedResponse = removeDataKeyFromRelationships(normalizedResponse)
        normalizedResponse = mergeObjects(normalizedResponse, state)

        return  { ...state, ...normalizedResponse }
      } else {
        return state
      }
    case 'ARTIST_SHOW_SUCCESS':
    case 'ARTIST_UPDATE_SUCCESS':
    case 'ARTIST_CREATE_SUCCESS':
      id = action.payload.data.id
      
      const existingRecord = state[id]

      let normalizedResponse = normalize(action.payload).artist

      normalizedResponse = removeDataKeyFromRelationships(normalizedResponse)

      if (existingRecord) {
        normalizedResponse[id] = mergeRelationships(normalizedResponse[id], existingRecord)
        normalizedResponse[id] = mergeLinks(normalizedResponse[id], existingRecord)
      }
      
      return { ...state, ...normalizedResponse }
    case 'ARTIST_UPDATE_VIDEO':
      id = action.payload.id

      return {
        ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...state[id].attributes,
            video: action.payload.video,
            videoDerivatives: action.payload.videoDerivatives
          }
        }
      }
    case 'ARTIST_DESTROY_SUCCESS':
      return omit(state, action.payload.data.id)
    case 'ARTIST_RESET_STATE':
      return {}
    case 'ADD_PERFORMANCES_TO_ARTIST':
      const existingPerformanceArray = get(state[action.payload.id], ['relationships', 'performances'], [])
      const newPerformanceArray = compareAndAddNewValues(existingPerformanceArray, action.payload.data)
      const showPast = action.payload.showPast


      if (newPerformanceArray.length !== existingPerformanceArray.length || showPast === true) {
        return { ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            relationships: {
              ...get(state[action.payload.id], 'relationships', {}),
              performances: showPast ? [ ...action.payload.data ] : [ ...newPerformanceArray ]
            }
          }
        }
      } else return state
    case 'ADD_PERFORMANCE_TO_ARTIST':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            performances: [ 
              ...action.payload.data, ...get(state[action.payload.id], ['relationships', 'performances'], [])
            ]
          }
        }
      }
    case 'ADD_NEARBY_PERFORMANCES_TO_ARTIST':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            nearbyPerformances: action.payload.data
          }
        }
      }
    case 'REMOVE_PERFORMANCE_FROM_ARTIST':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            performances: filter(
                state[action.payload.id].relationships.performances, e => {
                  return e.id !== action.payload.relationshipId
              })
          } 
        }
      }
    case 'ADD_BAND_TO_ARTIST':
    case 'ADD_BANDS_TO_ARTIST':
      const existingBandArray = get(state[action.payload.id], ['relationships', 'bands'], [])
      const newBandArray = compareAndAddNewValues(existingBandArray, action.payload.data)

      if (newBandArray.length !== existingBandArray.length) {
        return { ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            relationships: {
              ...get(state[action.payload.id], 'relationships', {}),
              bands: [ ...newBandArray ]
            } 
          }
        }
      } else return state
    case 'ADD_PRODUCT_TO_ARTIST':
    case 'ADD_PRODUCTS_TO_ARTIST':
      const existingProductArray = get(state[action.payload.id], ['relationships', 'products'], [])
      const newProductArray = compareAndAddNewValues(existingProductArray, action.payload.data)
      
      if (newProductArray.length !== existingProductArray.length) {
        return { ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            relationships: {
              ...get(state[action.payload.id], 'relationships', {}),
              products: [ ...newProductArray ]
            } 
          }
        }
      } else return state
    case 'REMOVE_PRODUCT_FROM_ARTIST':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            products: filter(state[action.payload.id].relationships.products, e => {
              return e.id !== action.payload.relationshipId
            })
          } 
        }
      }
    case 'REMOVE_BAND_FROM_ARTIST':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            bands: filter(state[action.payload.id].relationships.bands, e => {
              return e.id !== action.payload.relationshipId
            })
          } 
        }
      }
    case 'ADD_TRACKS_TO_ARTIST':
      const existingTrackArray = get(state[action.payload.id], ['relationships', 'tracks'], [])
      const newTrackArray = compareAndAddNewValues(existingTrackArray, action.payload.data)
      
      if (newTrackArray.length !== existingTrackArray.length ) {
        return { ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            relationships: {
              ...get(state[action.payload.id], 'relationships', {}),
              tracks: [ ...newTrackArray ]
            }
          }
        }
      } else return state
    case 'ADD_TRACK_TO_ARTIST':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            tracks: [ 
              ...action.payload.data, ...get(state[action.payload.id], ['relationships', 'tracks'], [])
            ]
          }
        }
      }
    case 'REMOVE_TRACK_FROM_ARTIST':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            tracks: filter(state[action.payload.id].relationships.tracks, e => {
              return e.id !== action.payload.relationshipId
            }) 
          }
        }
      }
    case 'REMOVE_SPOTIFY_TRACKS_FROM_ARTIST':
      return { ...state,
        [action.payload]: {
          ...state[action.payload],
          relationships: {
            ...get(state[action.payload], 'relationships', {}),
            tracks: filter(state[action.payload].relationships.tracks, e => {
              return !e.attributes?.isSpotifyTrack
            }) 
          }
        }
      }
    case 'REMOVE_APPLE_MUSIC_TRACKS_FROM_ARTIST':
      return { ...state,
        [action.payload]: {
          ...state[action.payload],
          relationships: {
            ...get(state[action.payload], 'relationships', {}),
            tracks: filter(state[action.payload].relationships.tracks, e => {
              return !e.attributes?.isAppleMusicTrack
            }) 
          }
        }
      }
    case 'ADD_SHOP_TO_ARTIST':
      return {...state, 
        [action.payload.id]: {
          ...state[action.payload.id],
          relationships: {
            ...get(state[action.payload.id], 'relationships', {}),
            shop: action.payload.shopId
          }
        }
      }
    case 'ARTIST_IMAGE_UPDATE_SUCCESS':
      id = action.payload.data.id

      return { ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...get(state[id], 'attributes', {}),
            image: action.payload.data.attributes.image,
            imageDerivatives: action.payload.data.attributes.imageDerivatives,
          }
        }
      }
    case 'ARTIST_IMAGES_INDEX_SUCCESS':
      id = action.payload.meta.imagableId

      return { ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...get(state[id], 'attributes', {}),
            images: action.payload.data
          }
        }
      }
    case 'ARTIST_IMAGES_CREATE_SUCCESS':
      id = action.payload.data.attributes.imagableId

      return { ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...get(state[id], 'attributes', {}),
            images: [
              action.payload.data, 
              ...get(state[id], ['attributes', 'images'], [])
            ] 
          }
        }
      }
    case 'ARTIST_IMAGES_DESTROY_SUCCESS':
      id = action.payload.data.attributes.imagableId

      return { ...state,
        [id]: {
          ...state[id],
          attributes: {
            ...get(state[id], 'attributes', {}),
            images: filter(state[id].attributes.images, e => {
              return e.id !== action.payload.data.id
            }) 
          }
        }
      }
    case 'SET_ARTIST_FETCHED_ASSOCIATIONS':
      let fetched = state[action.payload.id].fetched

      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          fetched: fetched ? [ ...fetched, action.payload.value ] : [action.payload.value]
        }
      }
    case 'ARTIST_INCREMENT_VIEW_COUNT_UPDATE_SUCCESS':
      return { ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          attributes: { ...state[action.payload.id].attributes,
            views: action.payload.count
          }
        }
      }
    case 'ARTIST_SAVE_LINKS':
      return { ...state, 
        [action.payload.id]: {
          ...state[action.payload.id],
          links: { ...(state[action.payload.id]?.links || {}),
            [action.payload.association]: action.payload.links
          }
        }
      }
    case 'ARTIST_MERCHANT_ACCOUNT_CREATE_SUCCESS':
      return { ...state, 
        [action.payload.id]: {
          ...state[action.payload.id],
          attributes: {
            ...state[action.payload.id].attributes,
            isMerchant: true,
            stripeId: action.payload.stripeId
          }
        }
      }
    default:
      return state
  }
}



const all = (state = [], action) => {
  switch (action.type) {
    case 'ARTISTS_INDEX_SUCCESS':
      return uniq([...state, ...map(action.payload.data, 'id')])
    case 'ARTIST_SHOW_SUCCESS':
    case 'ARTIST_CREATE_SUCCESS':
      return uniq([...state, action.payload.data.id])
    case 'ARTIST_DESTROY_SUCCESS':
      return filter(state, (e) => e !== action.payload.data.id)
    case 'ARTIST_RESET_STATE':
      return []
    default:
      return state
  }
}



const isFetching = (state = false, action) => {
  switch (action.type) {
    case 'ARTISTS_REQUESTED':
    case 'ARTIST_REQUESTED':
      return true
    case 'ARTISTS_INDEX_SUCCESS':
    case 'ARTIST_SHOW_SUCCESS':
    case 'ARTIST_CREATE_SUCCESS':
    case 'ARTIST_DESTROY_SUCCESS':
    case 'ARTIST_RESET_STATE':
    case 'ARTIST_SEARCH_INDEX_SUCCESS':
    case 'ARTISTS_ERROR':
    case 'ARTIST_ERROR':
      return false
    default:
      return state
  }
}



export default combineReducers({
  byId,
  all,
  isFetching
})

